@charset "UTF-8";
@media screen, projection {

    body {
    }

    .wrapper {
        display: flex;
        width: 100%;
    }

    #content {
        width: 100%;
        min-height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
    }

    .content-box {
        padding: 64px 12px 12px 12px;
    }

    .modal .content-box {
        padding: 12px;
    }

    .navigation {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 200px;
        background: #F8F9FA;
        z-index: 99;
    }

    .navigation-content {
        padding: 0px;
    }

    .navigation .nav-link-settings {
        color: #333333;
        padding: 0.23rem;
    }

    .navigation .nav-link {
        color: #666666;
        padding: 0.23rem 1.00rem;
    }

    .dropdown-menu .nav-link {
        color: #666666;
        padding: 0.23rem 0.23rem;
    }

    .navigation .nav-link-main {
        font-size: 0.90rem;
    }

    .navigation .nav-link-main {
        font-size: 0.90rem;
        padding: 0.56rem 1.00rem
    }

    .navigation .nav-link:hover {
        color: #333333;
    }

    .navigation ul ul .nav-link {
        padding: 0.10rem 1.40rem;
        font-size: 0.90em;
    }

    .dropdown-menu .nav-link {
        padding: 0.10rem 0.60rem;
        font-size: 0.90em;
    }

    .navigation ul ul .nav-link i, .navigation ul ul .nav-link svg, .dropdown-menu ul .nav-link i, .dropdown-menu ul .nav-link svg {
        font-size: 0.75em;
        color: #CCCCCC;
        margin: 0 4px 1px 1px;
    }

    .navigation .sub-navigation-content {
        border-top: 1px dotted #999999;
        padding: 10px 0;
    }

    .menu-input {
        width: 28px;
        font-size: 0.70rem;
        padding: 0;
        line-height: 1.0rem;
        text-align: center;
        border: 1px solid #CCCCCC;
        margin: 0;
    }

    #searchBox {
        width: 350px;
        max-width: 30vw;
    }

    .navigation-boxes {
        position: absolute;
        top: 53px;
        left: 10px;
        width: 375px;
        background: #F8F9FA;
        font-size: 0.75em;
        max-height: calc(100vh - 85px);
        overflow-y: auto;
    }

    .navigation-boxes-right {
        left: inherit;
        right: 10px;
        top: 60px;
        width: 350px;
        max-height: calc(100vh - 247px);
    }

    .navigation-boxes .info-box-close {
        padding: 5px;
    }

    .navigation-boxes a {
        color: #666666;
    }

    .navigation-boxes a.btn-success, .navigation-boxes a.btn-secondary {
        color: #FFFFFF;
    }

    .navigation-boxes a.font-weight-bold {
        color: #333333;
    }

    .navigation-box-fibers {
        top: 60px;
        right: 9px;
        left: auto;
    }

    .navigation-boxes h5 {
        font-size: 1.20em;
        color: #333333;
        padding: 12px 15px;
        margin: 0;
        border-top: 1px dotted #999999;
        border-bottom: 1px dotted #999999;
        text-transform: uppercase;
    }

    .navigation-boxes .card-header {
        font-weight: bold;
        text-transform: uppercase;
    }

    .navigation-boxes .card-body p {
        margin-bottom: 0.50rem;
    }

    .navigation-boxes .navigation-boxes-list-card {
        margin: 0 0 0.50rem 0;
    }

    .navigation-boxes .navigation-boxes-list-card:last-child {
        margin: 0;
    }

    .navigation-boxes .navigation-boxes-content .btn-group-sm > .btn, .navigation-boxes .navigation-boxes-content .btn-sm {
        padding: .15rem .25rem;
        font-size: .535rem;
        line-height: 1.4;
        border-radius: .2rem;
    }

    .navigation-boxes .equipment-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }

    .navigation-boxes .reserve-input {
        font-size: 0.70rem;
        padding: 0em 0.25em;
        height: calc(1.2em + .2rem + 2px);
        text-align: center;
        position: relative;
        margin-top: -14px;
    }

    .navigation-boxes .reserve-input-top {
        margin-top: -4px;
        z-index: 10;
        position: absolute;
    }

    .navigation-boxes .reserve-input-bottom {
        margin-top: -14px;
        z-index: 10;
    }

    .navigation-boxes .reserve-input-box {
        padding: 0.25rem 0.50rem;
        border: 1px solid #FFFFFF;
    }

    .navigation-boxes .accordion > .card .card-header {
        padding: 0;
    }

    .navigation-boxes .accordion > .card .card-header button {
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        color: #333333;
        font-size: 0.8rem;
    }

    .navigation-boxes-content .items-count {
        font-size: 0.65rem;
        color: #CCCCCC;
    }

    .nogps-list {
        padding-left: 20px;
    }

    .nogps-list a {
        color: #999999;
    }

    #menuSettingsDropdown ul.d-block {
        margin-top: 0.50rem;
        padding-top: 0.50rem;
        border-top: 1px dotted #999999;
    }

    #menuSettingsDropdown ul.d-block:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0px;
    }

    #lineInfo_fibers .fiber-pipe-info-title {
        font-weight: 600;
        padding: 0.50rem;
    }

    .navigaton-boxes-close {
        padding: 5px;
    }

    .navigation-boxes-content {
        padding: 15px; /*max-height: calc(100vh - 100px); overflow-y: auto;*/
    }

    .navigation-box-fibers .navigation-boxes-content {
        max-height: calc(100vh - 115px);
    }

    .navigation-boxes-content .list-group-item {
        padding: 0.25rem 0.50rem;
    }

    .navigation-boxes-separator {
        border-top: 1px dotted #999999;
        margin: 0.50em 0;
        clear: both;
    }

    .navigation-boxes .color-box-circle {
        width: 18px;
        height: 18px;
        border-width: 5px;
    }

    .color-box-ocuppied {
        background-color: #000000;
    }

    .navigation-boxes .color-box-cabel {
        width: 5px;
        height: 5px;
        border-width: 5px;
    }

    .logged-in-user {
        background: #FFFFFF;
        padding: 0.25rem 0.25rem 0.25rem 1.00rem;
        font-size: 0.90rem;
        border-radius: 3px;
        line-height: 1.40rem;
    }

    .logged-in-user a {
        font-size: 0.60rem !important;
    }


    .fiber-item {
        border: 1px solid #CCCCCC;
        padding: 10px;
        margin-top: 10px;
        display: none;
        border-radius: 3px;
    }

    .fiber-item:first-child {
        margin-top: 0px;
    }

    .fiber-item-highlight {
        display: block;
    }

    .pipe-item {
        border: 1px solid #CCCCCC;
        padding: 10px;
        margin-top: 10px;
        border-radius: 3px;
    }

    .navigation-icons {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .navigation-icons .dropdown-toggle::after {
        display: none;
    }

    .navigation-right {
        position: absolute !important;
        top: 10px;
        right: 57px;
    }

    .navigation, .navigation-icons .btn-group-border, .navigation-right .btn-group-border, .navigation-boxes {
        border: 1px solid #999999;
        border-radius: 3px;
        box-shadow: 0px 0px 3px #999999;
    }

    .action-buttons {
        padding: 15px 12px;
        text-align: right;
        float: right;
    }

    #actionButtons {
        position: absolute;
        bottom: 29px;
        right: 60px;
        border: 2px solid #FFFFFF;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 0px 5px 0 #000000;
    }

    #actionButtons button {
        border-left: 1px solid #FFFFFF;
    }

    #actionButtons button:first-child {
        border-left: 0px;
    }

    .btn-group label {
        margin-bottom: 0;
    }

    h1.display-4 {
        margin-bottom: 1.5rem;
        font-size: 2.50rem;
    }

    .modal.show .modal-dialog.modal-xl {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-xl .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }


    .overlay {
        display: none;
        position: fixed;
        /* full screen */
        width: 100vw;
        height: 100vh;
        /* transparent black */
        background: rgba(0, 0, 0, 0.7);
        /* middle layer, i.e. appears below the sidebar */
        z-index: 998;
        opacity: 0;
        /* animate the transition */
        transition: all 0.5s ease-in-out;
    }

    /* display .overlay when it has the .active class */
    .overlay.active {
        display: block;
        opacity: 1;
    }

    #dismiss {
        width: 35px;
        height: 35px;
        position: absolute;
        /* top right corner of the sidebar */
        top: 10px;
        right: 10px;
    }

    .card-item {
        padding: 0.75rem 0px;
    }

    .card-item:first-child {
        padding-top: 0px;
    }

    .card-item:last-child {
        padding-bottom: 0px;
    }

    .map-alert {
        position: fixed !important;
        top: 5px;
        right: 60px;
        z-index: 9999;
    }

    .color-box {
        width: 25px;
        height: 25px;
        border: 1px solid #FFFFFF;
        box-shadow: 0 0 2px #666666;
    }

    .color-box-circle {
        width: 25px;
        height: 25px;
        border: 7px solid #FFFFFF;
        border-radius: 50%;
        box-shadow: 0 0 2px #666666;
    }

    ul.ui-autocomplete {
        font-size: 0.80em;
    }

    .ui-autocomplete-category {
        text-transform: uppercase;
        font-weight: bold;
        background: #D0D0D0;
    }

    .ui-autocomplete-category div {
        position: relative;
        padding: 3px 1em 3px .4em;
    }

    .ui-state-active,
    .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active,
    a.ui-button:active,
    .ui-button:active,
    .ui-button.ui-state-active:hover {
        border: 1px solid #D0D0D0;
        background: #DDDDDD;
        color: #000000;
    }

    .ui-menu-item i, .ui-menu-item.ui-state-active {
        color: #999999;
    }

    .box-meansure {
        position: absolute;
        bottom: 24px;
        right: 60px;
    }

    .form-group .evo-colorind {
        border: 1px solid #999999;
        box-shadow: 0px 0px 2px #CCCCCC;
        border-radius: 0.25rem;
        height: calc(1.5em + .75rem - 10px) !important;
        width: calc(1.5em + .75rem - 10px) !important;
        margin: calc(-1.5em - .50rem) 0.40rem 0 0 !important;
    }

    .list-group-item .evo-pop {
        position: fixed !important;
        width: 212px !important;
    }

    .list-group-item .evo-colorind {
        width: 14px !important;
        height: 14px !important;
        margin-top: 2px;
        border-radius: 7px;
        box-shadow: 0px 0px 5px #000000;
    }

    .fiber-title {
        padding: 5px 10px;
        font-size: 0.75em;
        border: 1px solid #DDDDDD;
        color: #666666;
        background-color: #F6F6F6;
        border-radius: 3px;
        position: relative;
        z-index: 5;
        margin: 4px 25px -4px 25px;
        max-width: 175px;
        white-space: nowrap;
        cursor: help;
        overflow: hidden;
    }

    .fiber-title:hover {
        max-width: none;
    }

    .fiber-connection {
        height: 25px;
        position: relative;
        margin: -33px 0 5px 0;
    }

    .fiber-connection .fiber-connector {
        width: 11px;
        height: 11px;
        position: absolute;
        margin: 11px 0;
        cursor: help;
        background-color: #000000;
        z-index: 3;
    }

    .fiber-connection .fiber-connector-1 {
        display: none;
    }

    .fiber-connection .fiber-connector-left {
        left: 0px;
    }

    .fiber-connection .fiber-connector-right {
        right: 0px;
    }

    .fiber-connection .fiber-connector-middle {
        left: 50%;
        margin-left: -6px;
    }

    .fiber-connection .fiber-connector-middle-patchcord-left {
        left: 50%;
        margin-left: -46px;
        z-index: 4;
    }

    .fiber-connection .fiber-connector-middle-patchcord-middle {
        left: 50%;
        z-index: 2;
        display: block;
        height: 3px;
        margin: 15px 0 15px -46px;
        width: 92px;
        position: absolute;
        background-color: #0000FF;
    }

    .fiber-connection .fiber-connector-middle-patchcord-right {
        left: 50%;
        margin-left: 46px;
        z-index: 4;
    }

    .fiber-connection .fiber-line {
        display: block;
        height: 3px;
        margin: 15px 0;
        width: 50%;
        position: absolute;
    }

    .fiber-connection .fiber-left {
        left: 0px;
    }

    .fiber-connection .fiber-right {
        right: 0px;
    }

    .fiberset-childs {
        padding: 10px;
    }

    .fiberset-childs-item {
        padding: 0 0 0 10px;
    }

    .fiber-connection .fiber-splitter {
        position: absolute;
        left: 50%;
        margin-left: -6px;
        margin-top: 3px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #000000;
        z-index: 5;
        cursor: help;
    }

    .fiber-connection .fiber-splitter-in {
        border-left: 12px solid #000000;
        border-right: 0px none;
    }

    .fiber-connection-splitter .fiber-line {
        width: calc(50% - 50px);
    }

    .fiber-connection-splitter .fiber-splitter-line {
        width: 100px;
        left: 50%;
        display: block;
        height: 3px;
        margin: 15px -50px;
        position: absolute;
        background-color: #000000;
    }

    .fiber-connection-splitter .fiber-splitter-half-line {
        width: 50px;
        left: 50%;
        display: block;
        height: 3px;
        margin: 15px -50px 15px 0;
        position: absolute;
        background-color: #000000;
    }

    .fiber-connection-splitter .fiber-connector-middle {
        left: calc(50% - 50px);
    }

    .fiber-connection-splitter .fiber-connector-middle-after {
        left: calc(50% + 50px);
    }

    .fiber-connection-splitter .fiber-connector-middle-patchcord-left {
        left: calc(50% - 50px);
        margin-left: -92px;
    }

    .fiber-connection-splitter .fiber-connector-middle-patchcord-middle {
        left: calc(50% - 50px);
        margin-left: -92px;
    }

    .fiber-connection-splitter .fiber-connector-middle-patchcord-right {
        left: calc(50% - 50px);
        margin-left: -6px;
    }

    .fiber-connection-splitter .fiber-connector-middle-patchcord-left-after {
        left: calc(50% + 50px);
        margin-left: -6px;
    }

    .fiber-connection-splitter .fiber-connector-middle-patchcord-middle-after {
        left: calc(50% + 50px);
        margin-left: 0px;
    }

    .fiber-connection-splitter .fiber-connector-middle-patchcord-right-after {
        left: calc(50% + 50px);
        margin-left: 86px;
    }

    .fiberset-path {
        padding: 0px;
        margin: 0px;
    }

    .fiberset-path .fiberset-path-content {
        padding: 0px 10px 0px 10px;
        margin-top: -16px;
        position: relative;
        overflow: hidden;
    }

    .fiberset-path .fiberset-path-content:first-child {
        margin-top: 0px;
    }

    .fiberset-path .fiberset-path-content-item {
        float: left;
        border-left: 3px solid #000000;
        margin: 0 10px;
        width: 20px;
        min-height: 45px;
        padding: 16px 0px 0px 0px;
    }

    .fiberset-path .fiberset-path-content:first-child .fiberset-path-content-item {
        padding-top: 0px;
    }

    .fiberset-path .fiberset-path-content-item-box {
        padding: 15px 0;
        border: 1px dotted #666666;
        border-left: 0px none;
    }

    .fiberset-path .fiberset-path-item {
        padding: 0px;
        white-space: nowrap;
    }

    .fiberset-path .fiberset-point {
        position: absolute;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: -9px;
        top: 17px;
        background: #000000;
        z-index: 99;
    }

    .fiberset-path .fiberset-connection {
        position: relative;
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-left: -6px;
        background: #000000;
        float: left;
        z-index: 99;
    }

    .fiberset-path .fiberset-connection {
        position: relative;
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-left: -6px;
        background: #000000;
        float: left;
        z-index: 99;
    }

    .fiberset-path .fiberset-path-content-item-box .fiberset-connection-space {
        height: 29px;
        width: 3px;
        display: block;
        border-left: 3px solid #000000;
        margin-left: -3px;
    }

    .fiberset-path .fiberset-path-content-item-box .fiberset-connection-space-top {
        margin-bottom: -17px;
    }

    .fiberset-path .fiberset-splitter {
        position: absolute;
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 8px solid #000000;
        border-left-color: transparent;
        border-right-color: transparent;
        margin-left: -10px;
        z-index: 99;
    }

    .fiberset-path .fiberset-splitter-in {
        border-top-color: transparent;
        margin-bottom: 8px;
        margin-bottom: -8px;
    }

    .fiberset-path .fiberset-splitter-out {
        border-bottom-color: transparent;
        margin-top: 8px;
    }

    .fiberset-path .fiber-splitter-connected {
        position: relative;
        margin-top: 18px;
        margin-bottom: -29px;
        margin-left: -13px;
        cursor: pointer;
    }

    .fiberset-path .fiberset-pachcord {
    }

    .fiberset-path .fiberset-pachcord .fiberset-pachcord-connection {
        position: relative;
        display: block;
        width: 9px;
        height: 9px;
        margin-left: -6px;
        background: #000000;
        z-index: 99;
    }

    .fiberset-path .fiberset-pachcord .fiberset-pachcord-line {
        position: relative;
        display: block;
        width: 3px;
        height: 25px;
        margin-left: -3px;
        background: #0000FF;
    }

    .fiberset-path .fiberset-path-content:first-child .fiberset-point {
        top: 0px;
    }

    .fiberset-path .fiberset-path-content:first-child .fiberset-path-item {
        padding-top: 0px;
    }

    .fiberset-path .fiberset-path-content:last-child .fiberset-point {
        bottom: 0px;
        top: inherit;
    }

    .fiberset-path .fiberset-path-content:last-child .fiberset-path-item {
        padding-bottom: 0px;
    }

    .icon-help {
        color: #999999;
        cursor: help;
        vertical-align: super;
        font-size: 0.75rem;
    }

    .setLine-slider {
        background: #FFFFFF;
        padding: 5px 5px 1px 5px;
        border: 1px solid #CCCCCC;
        border-radius: 3px;
        position: absolute;
        bottom: -4px;
        right: 42px;
        z-index: 99;
    }

    .setLine-slider-label {
        display: inline-block;
        padding: 1px 5px;
        float: left;
        background: #E6E6E6;
        width: 20px;
        line-height: 18px;
        height: 20px;
        text-align: center;
        margin-right: 5px;
        border-radius: 10px;
        font-size: 0.70rem;
    }

    .item-list-icon {
        margin: 3px;
    }

    option:disabled {
        color: #CCCCCC;
    }

    .border-set {
        font-size: 0.7rem;
        margin-left: 4px;
        color: #999999;
    }

    #map {
        width: 100%;
        min-height: 100vh;
    }

    .table-hover tbody tr:hover {
        color: #FFFFFF;
        background-color: #F07D00;
    }

    .table-hover tbody tr:hover a.link {
        color: #FFFFFF;
    }

    .table-small {
        font-size: 0.85rem;
    }

    .table-row-error {
        background-color: #FFCCCD;
    }

    .table-row-inactive {
        background-color: #DDDDDD;
    }

    .nav-tabs {
        margin-bottom: 1rem;
    }

    .tab-content .tab-pane {
        display: none;
    }

    .tab-content .active {
        display: block;
    }

    table.internalwiring {
        table-layout: fixed;
        margin: 0;
    }

    table.internalwiring td.normal {
        width: 350px;
    }

    table.internalwiring td.large {
        width: 550px;
    }

    table.internalwiring tr, table.internalwiring td {
        padding: 0;
        margin: 0;
    }

    div.internal-cabel {
        height: 12px;
        background-color: #000000;
        margin: 20px 10px 0 0;
    }

    div.internal-cabel-label {
        text-align: center;
        position: relative;
        margin-top: -21px;
    }

    div.internal-cabel-label span {
        display: inline-block;
        padding: 0.25rem 0.50rem;
        background-color: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 5px;
        font-size: 0.80rem;
    }

    .internalwiring-mainbox {
        position: relative;
    }

    .internalwiring-mainwire {
        float: right;
        width: 10px;
        height: calc(100% - 50px);
        border-top: 2px solid #DDDDDD;
        border-right: 2px solid #DDDDDD;
        position: absolute;
        top: 0px;
        right: 0px;
        margin: 20px 0;
    }

    .internalwiring-wire {
        margin: 20px 0 0 0;
        height: 2px;
        width: 100%;
    }

    .internalwiring-wire-long {
        width: 100%;
    }

    .internalwiring-button {
        margin: 10px;
        padding: 0.125rem 0.25rem;
        font-size: 0.70rem;
    }

    .internalwiring-form {
        position: absolute;
        margin-top: -35px;
        margin-left: -200px;
        width: 280px;
    }

    .internawiring-connection {
        float: right;
        margin-top: 5px;
        width: 25%;
        position: relative;
    }

    .internawiring-connection .fiber-connector {
        width: 11px;
        height: 11px;
        margin: 11px 0;
        cursor: help;
        background-color: #000000;
        z-index: 3;
    }

    .internawiring-connection .fiber-connector-1 {
        display: none;
    }

    .internawiring-connection .fiber-connector-left {
        float: left;
    }

    .internawiring-connection .fiber-connector-right {
        float: right;
    }

    .internawiring-connection .fiber-connector-middle {
        left: 50%;
        margin-left: -6px;
    }

    .internawiring-connection .fiber-connector-middle-patchcord-left {
        float: left;
        z-index: 4;
    }

    .internawiring-connection .fiber-connector-middle-patchcord-middle {
        z-index: 2;
        display: block;
        height: 3px;
        margin: 15px 0;
        width: 100%;
        background-color: #0000FF;
    }

    .internawiring-connection .fiber-connector-middle-patchcord-right {
        float: right;
        z-index: 4;
    }


    .internal-tub {
        padding: 1.00rem;
        border: 3px solid #DDDDDD;
        border-radius: 15px;
    }

}

@media only screen and (max-width: 40.0625em) {

    .navigation-boxes h5 { font-size: 0.75rem; }

    .navigation-boxes {
        width: 235px;
        max-height: 70vh;
        overflow-y: auto;
    }

    .navigation-boxes-right {
        right: inherit;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        z-index: 999;
    }

    #showUserSearchXs {
        position: absolute;
        bottom: 24px;
        left: 10px;
    }

    #btnUserXs {
        display: none;
        position: absolute;
        left: 35px;
        bottom: 0px;
        width: 198px;
        white-space: nowrap;
        max-width: calc(100vw - 205px);
    }

    #searchBox {
        display: none;
        max-width: calc(100vw - 250px);
    }

}